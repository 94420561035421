<template>
  <div>
    <el-dialog title="Export" :visible.sync="setShow" @close="closeEvent" width="30%">
      <eden-file-export :data="exportData" @exported="closeEvent" @cancelled="closeEvent">
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields" :max="form.format === 'pdf' ? 5 : 7">
                  <el-checkbox v-for="(value, key, i) in fields" :key="i" :label="key"
                    :disabled="key === 'customer' || key === 'meals'">
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio v-for="(key, value, i) in formats" :key="i" :label="value">
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Group by data grouping">
                <el-radio-group v-model="form.group_by">
                  <el-radio :label="'meals'">Meals</el-radio>
                  <el-radio :label="'customers'">Customers</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button type="primary" :loading="exporting" :disabled="disabled" @click="getExportData">Export</el-button>
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
import { formatMealData } from "@/components/Orders/Schedule/format-schedule-data";
import schedule from "@/requests/orders/schedule";

export default {
  name: "OrderScheduleMealExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    servicePartnerId: {
      type: [String, Number],
      default: null,
    },
    period: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    customDate: {
      type: Object,
      default() {
        return {};
      },
    },
    dataTime: {
      type: [Date, String],
      default: "",
    },
  },

  data() {
    return {
      fields: {
        order_id: "Order Id",
        customer: "Customer",
        meals: "Meals",
        gardener: "Gardener",
        location: "Location Area",
        address: "Address",
        packed: "Packed",
        dispatched: "Dispatched",
        order_type: "Order Type",
        menu_type: "Menu Type",
        landmark: "Landmark",
        delivery_date: "Delivery Date",
        order_date: "Order Date"
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["customer", "meals"],
        format: "",
        group_by: "",
      },
      exportData: {},
      exporting: false,
    };
  },

  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    disabled() {
      return (
        !this.form.format || !this.form.fields.length || !this.form.group_by
      );
    },
  },
  methods: {
    closeEvent() {
      this.form.fields = ["customer", "meals"];
      this.form.format = "";
      this.exporting = false;
      this.setShow = false;
    },
    getExportData() {
      this.exporting = true;
      if (this.data.length) {
        this.formatExportData(this.data);
        return;
      }

      schedule
        .export({
          servicePartnerId: this.servicePartnerId,
          period: this.period,
          start_date: this.customDate.from,
          end_date: this.customDate.to
        })
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            const formattedMealData = formatMealData(data, true);
            this.formatExportData(formattedMealData);
          } else {
            this.exporting = false;
          }
        })
        .catch((error) => {
          this.exporting = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    formatExportData(data) {
      console.log(data, "which data")
      try {
        this.mixpanelTrack({
          module: "Order Fulfillment",
          action: "Export",
          value: "",
        });

        this.mixpanelTrack({
          event: `order_fulfillment_export`,
        });

        let title;
        if (this.period) {
          let period;
          const fPeriod = this.formatPeriodDate(this.period, "do m, y");
          if (this.period === 'custom') {
            const customFromDate = this.formatDate(this.customDate.from, "ddd do, m y");
            const customToDate = this.formatDate(this.customDate.to, "ddd do, m y");
            period = `${customFromDate} - ${customToDate}`;
          } else {
            period = fPeriod.start ? `${fPeriod.start} - ${fPeriod.end}` : fPeriod;
          }
          let updated = this.formatDateAndTime(this.dataTime, "do m, y");
          let date = this.formatDateAndTime(new Date(), "do m, y");
          title = `Orders Schedule - ${period}\nDownloaded at - ${updated}\nExported at - ${date}`;
        } else {
          const date = this.formatDate(this.$route.params.date, "ddd do, m y");
          title = `Orders Schedule - ${date}`;
        }
        let headers = {
          sn: "S/N",
        };
        let extra_headers = {};
        let fulfillmentData = [];

        this.form.fields.forEach(
          (key) => (extra_headers[key] = this.fields[key]),
        );
        headers = { ...headers, ...extra_headers };

        fulfillmentData =
          this.form.group_by === "meals"
            ? this.formatExportDataMeals(data, headers)
            : this.formatExportDataByCustomers(data, headers);
        this.exportData = {
          title,
          headers,
          data: fulfillmentData,
          fileName: "Order Schedule",
          titleMargin: 44,
          format: this.form.format,
        };
        this.exporting = false;
      } catch (error) {
        console.log(error);
      }
    },
    formatExportDataMeals(data, headers) {
      let meals = [];

      let sn = 1;
      data.forEach((customer) => {
        let export_object = {};
        Object.keys(headers).forEach((key) => {
          switch (key) {
            case "order_id":
              export_object[key] = customer.order_id;
              break;
            case "customer":
              export_object[key] = this.formatName(customer.customer || "");
              break;
            case "meals":
              export_object[key] = customer.meals.length
                ? customer.meals
                  .map((meal) => {
                    return this.formatMealName(meal);
                  })
                  .join("\n")
                : "-";
              break;
            case "gardener":
              export_object[key] = customer.gardener;
              break;
            case "location":
              export_object[key] = customer.location_area;
              break;
            case "address":
              export_object[key] = customer.customer_address;
              break;
            case "landmark":
              export_object[key] = customer.landmark;
              break;
            case "packed":
              export_object[key] = customer.packed ? "Packed" : "Not packed";
              break;
            case "dispatched":
              export_object[key] = customer.dispatched
                ? "Dispatched"
                : "Not dispatched";
              break;
            case "order_type":
              export_object[key] = customer.order_type;
              break;
            case "menu_type":
              export_object[key] = customer.menu_type;
              break;
            case "delivery_date":
              export_object[key] = this.formatDate(customer.delivery_date, "do m, y");
              break;
            case "order_date":
              export_object[key] = this.formatDate(customer.created_at, "ddd do, m y");
              break;
            default:
              break;
          }
        });

        customer.meals?.forEach((meal) => {
          meals.push({
            sn,
            ...export_object,
            meals: this.formatMealName(meal),
          });

          sn += 1;
        });
      });

      return meals;
    },
    formatExportDataByCustomers(data, headers) {
      return data.map((item, index) => {
        let export_object = {};
        Object.keys(headers).forEach((key) => {
          switch (key) {
            case "sn":
              export_object[key] = index + 1;
              break;
            case "customer":
              export_object[key] = this.formatName(item.customer);
              break;
            case "order_id":
              export_object[key] = item.order_id;
              break;
            case "meals":
              export_object[key] = item.meals.length
                ? item.meals
                  .map((meal) => {
                    return this.formatMealName(meal);
                  })
                  .join("\n")
                : "-";
              break;
            case "location":
              export_object[key] = item.location_area;
              break;
            case "address":
              export_object[key] = item.customer_address;
              break;
            case "landmark":
              export_object[key] = item.landmark;
              break;
            case "packed":
              export_object[key] = item.packed ? "Packed" : "Not packed";
              break;
            case "dispatched":
              export_object[key] = item.dispatched
                ? "Dispatched"
                : "Not dispatched";
              break;
            case "order_type":
              export_object[key] = item.order_type;
              break;
            case "menu_type":
              export_object[key] = item.menu_type;
              break;
            case "delivery_date":
            export_object[key] = this.formatDate(item.delivery_date, "do m, y");
              break;
            case "order_date":
              export_object[key] = this.formatDate(item.created_at, "ddd do, m y");
              break;
            default:
              break;
          }
        });
        return export_object;
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
