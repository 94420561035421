export const formatBeautyData = (data) => {
  return data.map((order) => {
    const extra = order.extra_details ?? "";
    return {
      order_id: order.order_id,
      order_type: order.order_type,
      customer: order.customer_name,
      created_at: order.created_at,
      customer_id: order.user_id,
      channel_id: order.channel_id,
      gardener: order.gardener_for_customer,
      type: extra ? extra.beauty_type : "-",
      is_one_time_order: order.is_one_time_order,
      services_list: order.extra_details.services_list,
      sp_first: extra
        ? extra.sp_first_beauty_item
          ? extra.sp_first_beauty_item.name
          : "-"
        : "-",
      sp_second: extra
        ? extra.sp_second_beauty_item
          ? extra.sp_second_beauty_item.name
          : ""
        : "-",
      arrival_date: order.deliver_to_customer || "-",
      customer_phone_number: order.customer_phone_no || "",
      priority: order.zone_priority || "-",
      locationarea: order.location_area || "-",
      customer_address: order.customer_address || "-",
      pre_sp_assigned: {
        status: true,
      },
      status:
        order.last_marked_activity !== null
          ? order.last_marked_activity
          : "Not Assigned",

      sp_assigned: {
        status: extra && !!extra.is_sp_assigned,
        time: (extra && extra.sp_assigned_time) || "-",
        name: extra && extra.sp_assigned_by ? extra.sp_assigned_by.name : "-",
      },
      sp_enroute: {
        status: extra && !!extra.is_sp_enroute,
        time: (extra && extra.sp_enroute_time) || "-",
        name: extra && extra.sp_enroute_by ? extra.sp_enroute_by.name : "-",
      },
      sp_arrived: {
        status: extra && !!extra.is_sp_arrived,
        time: (extra && extra.sp_arrived) || "-",
        name: extra && extra.sp_arrived_by ? extra.sp_arrived_by.name : "-",
      },
      completed: {
        status: extra && !!extra.is_completed,
        time: (extra && extra.completed_time) || "-",
        name: extra && extra.completed_by ? extra.completed_by.name : "-",
      },
    };
  });
};

export const formatCleaningData = (data) => {
  return data.map((order) => {
    const extra = order.extra_details ?? {};
    return {
      order_id: order.order_id,
      customer: order.customer_name,
      customer_id: order.user_id,
      created_at: order.created_at,
      channel_id: order.channel_id,
      gardener: order.gardener_for_customer,
      type: extra ? extra.cleaning_type : "",
      order_type: order.order_type,
      is_one_time_order: order.is_one_time_order,
      sp: extra
        ? extra.service_provider
          ? extra.service_provider.name
          : "-"
        : "-",
      arrival_date: order.deliver_to_customer || "-",
      customer_phone_number: order.customer_phone_no || "",
      priority: order.zone_priority || "-",
      locationarea: order.location_area || "-",
      // landmark: order.users.landmark || "-",
      customer_address: order.customer_address || "-",
      cleaner_assigned: {
        status: !!order.is_cleaner_assigned,
        time: order.cleaner_assigned_time || "-",
        name: order.cleaner_assigned_by ? order.cleaner_assigned_by.name : "-",
        cleaners: [],
      },
      cleaner_enroute: {
        status: !!order.is_cleaner_enroute,
        time: order.cleaner_enroute_time || "-",
        name: order.cleaner_enroute_by ? order.cleaner_enroute_by.name : "-",
      },
      cleaner_arrived: {
        status: !!order.is_cleaner_arrived,
        time: order.cleaner_arrival_time || "-",
        name: order.cleaner_arrived_by ? order.cleaner_arrived_by.name : "-",
      },
      cleaning_started: {
        status: !!order.is_cleaning_started,
        time: order.cleaning_started_time || "-",
        name: order.cleaning_started_by ? order.cleaning_started_by.name : "-",
      },
      cleaning_done: {
        status: !!order.is_cleaning_done,
        time: order.cleaning_done_time || "-",
        name: order.cleaning_done_by ? order.cleaning_done_by.name : "-",
      },
    };
  });
};

export const formatLaundryPickupData = (data) => {
  return data.map((order) => {
    const extra = order.extra_details ?? {};
    return {
      order_id: order.order_id,
      order_type: order.order_type,
      customer: order.customer_name,
      created_at: order.created_at,
      customer_id: order.user_id,
      channel_id: order.channel_id,
      customer_phone_number: order.pickup_phone_no || "",
      customer_address: order.pickup_home_address || "-",
      delivery_date: order.deliver_to_customer || "-",
      // landmark: order.users.landmark || "-",
      gardener: order.gardener_for_customer,
      type: extra ? extra.laundry_type : "",
      is_one_time_order: order.is_one_time_order,
      quantity: extra.quantity ?? "",
      items: order.tally_items ? order.tally_items.tally_items : [],
      pickup: order.pickup_from_customer || "-",
      sp: extra
        ? extra.service_provider
          ? extra.service_provider.name
          : "-"
        : "-",
      priority: order.zone_priority || "-",
      locationarea: order.pickup_location_area || "-",
      pre_sp_assigned: {
        status: true,
      },
      sp_assigned: {
        status: !!extra.is_sp_assigned,
        time: extra.is_sp_assigned_at || "-",
        name: extra.is_sp_assigned_by ? extra.is_sp_assigned_by.name : "-",
      },
      rider_enroute: {
        status: !!extra.is_rider_enroute,
        time: extra.is_rider_enroute_at || "-",
        name: extra.is_rider_enroute_by ? extra.is_rider_enroute_by.name : "-",
      },
      pickup_rider_arrived: {
        status: !!extra.is_pickup_rider_arrived,
        time: extra.is_pickup_rider_arrived_at || "-",
        name: extra.is_pickup_rider_arrived_by
          ? extra.is_pickup_rider_arrived_by.name
          : "-",
      },
      rider_pickup: {
        status: !!extra.is_rider_pickup,
        time: extra.is_rider_pickup_at || "-",
        name: extra.is_rider_pickup_by ? extra.is_rider_pickup_by.name : "-",
      },
      pickup_rider_delivered: {
        status: !!extra.is_pickup_rider_delivered,
        time: extra.is_pickup_rider_delivered_at || "-",
        name: extra.is_pickup_rider_delivered_by
          ? extra.is_pickup_rider_delivered_by.name
          : "-",
      },
      pickup_qc: {
        status: !!extra.is_pickup_qc,
        time: extra.is_pickup_qc_at || "-",
        name: extra.is_pickup_qc_by ? extra.is_pickup_qc_by.name : "-",
      },
      sent_to_sp: {
        status: !!extra.is_sent_to_sp,
        time: extra.is_sent_to_sp_at || "-",
        name: extra.is_sent_to_sp_by ? extra.is_sent_to_sp_by.name : "-",
      },
    };
  });
};

export const formatLaundryDeliveryData = (data) => {
  return data.map((order) => {
    const extra = order.extra_details ?? {};
    return {
      order_id: order.order_id,
      order_type: order.order_type,
      created_at: order.created_at,
      customer: order.customer_name,
      customer_id: order.user_id,
      channel_id: order.channel_id,
      customer_phone_number: order.customer_phone_no || "",
      customer_address: order.customer_address || "-",
      // landmark: order.users.landmark || "-",
      gardener: order.gardener_for_customer,
      type: extra ? extra.laundry_type : "",
      quantity: extra.quantity ?? "",
      delivery: order.deliver_to_customer || "-",
      is_one_time_order: order.is_one_time_order,
      sp: extra
        ? extra.service_provider
          ? extra.service_provider.name
          : ""
        : "",
      priority: order.zone_priority || "-",
      locationarea: order.location_area || "-",
      sent_to_sp: {
        status: !!extra.is_sent_to_sp,
        time: extra.is_sent_to_sp_at || "-",
        name: extra.is_sent_to_sp_by ? extra.is_sent_to_sp_by.name : "-",
      },
      received_from_sp: {
        status: !!extra.is_received_from_sp,
        time: extra.is_received_from_sp_at || "-",
        name: extra.is_received_from_sp_by
          ? extra.is_received_from_sp_by.name
          : "-",
      },
      delivery_qc: {
        status: !!extra.is_delivery_qc,
        time: extra.is_delivery_qc_at || "-",
        name: extra.is_delivery_qc_by ? extra.is_delivery_qc_by.name : "-",
      },
      dispatched: {
        status: !!extra.is_dispatched,
        time: extra.is_dispatched_at || "-",
        name: extra.is_dispatched_by ? extra.is_dispatched_by.name : "-",
      },
      delivery_rider_arrived: {
        status: !!extra.is_delivery_rider_arrived,
        time: extra.is_delivery_rider_arrived_at || "-",
        name: extra.is_delivery_rider_arrived_by
          ? extra.is_delivery_rider_arrived_by.name
          : "-",
      },
      delivery_rider_delivered: {
        status: !!extra.is_delivery_rider_delivered,
        time: extra.is_delivery_rider_delivered_at || "-",
        name: extra.is_delivery_rider_delivered_by
          ? extra.is_delivery_rider_delivered_by.name
          : "-",
      },
    };
  });
};

export const formatMealData = (data, forExport = false) => {
  return data.map((order) => {
    const meals = forExport
      ? order.items_assigned_to_order
      : order.items_assigned_to_order.map((meal) => meal);
    return {
      order_id: order.order_id,
      customer: order.customer_name,
      gardener: order.gardener_for_customer,
      order_type: order.order_type,
      channel_id: order.channel_id,
      is_one_time_order: order.is_one_time_order,
      menu_type: order.menu_type || "-",
      meals,
      // landmark: order.users.landmark || "-",
      delivery_date: order.deliver_to_customer || "-",
      customer_phone_number: order.customer_phone_no || "",
      priority: order.zone_priority || "-",
      location_area: order.location_area || "-",
      customer_address: order.customer_address || "-",
      packed: !!order.is_packed,
      packed_at: order.is_packed_time || null,
      created_at: order.created_at,
      packed_by: order.packed_by ? order.packed_by.name : null,
      dispatched: !!order.is_dispatched,
      dispatched_at: order.is_dispatched_time || null,
      dispatched_by: order.dispatched_by ? order.dispatched_by.name : null,
    };
  });
};
