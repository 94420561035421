<template>
  <div>
    <eden-page-header
      :title="'Order Fulfillment'"
      :subtitle="'History'"
      :section="title"
    />

    <eden-table-actions :title="tableTitle" :show-search="false">
      <template slot="title">
        <div class="flex justify-end align-center">
          <eden-service-partners
            :service-partner-name.sync="servicePartnerName"
            :service-partner-id.sync="servicePartnerId"
            :service="'meal'"
          />
        </div>
      </template>
      <template slot="actions">
        <el-button
          type="plain"
          icon="eden-icon-upload"
          class="ml-10"
          :disabled="loading || !pageData.length"
          @click="exporting = true"
        >
          Export
        </el-button>
      </template>
    </eden-table-actions>
    <p v-if="loading">Loading</p>
    <template v-else>
      <el-table :data="pageData">
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header">
              <span>Customer</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm"> {{ formatName(scope.row.customer) }}</span>
            <span class="font-xsm text-grey-tertiary">
              {{ scope.row.gardener }}</span
            >
          </template>
        </el-table-column>
        <el-table-column width="350">
          <template slot="header">
            <div class="table--header">
              <span>Food</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <ul class="meals">
              <li v-for="(item, i) in scope.row.meals" :key="i">
                {{ formatMealName(item) }}
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column width="150">
          <template slot="header">
            <div class="table--header">
              <span>Location/area</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ scope.row.location_area }} ({{ scope.row.priority }})</span
            >
          </template>
        </el-table-column>
        <el-table-column width="300">
          <template slot="header">
            <div class="table--header">
              <span>Address</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm"> {{ scope.row.customer_address }}</span>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header">
              <span>Packing status</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <order-function-status
              :allow-marking="false"
              :mark-function="'packing'"
              :function-status="!!scope.row.packed"
              :function-time="scope.row.packed_at"
              :function-actor="scope.row.packed_by"
            />
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header">
              <span>Dispatching status</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <order-function-status
              :allow-marking="false"
              :mark-function="'dispatching'"
              :function-status="!!scope.row.dispatched"
              :function-time="scope.row.dispatched_at"
              :function-actor="scope.row.dispatched_by"
            />
          </template>
        </el-table-column>
      </el-table>
    </template>
    <orders-schedule-meal-export :show.sync="exporting" :data="pageData" />
  </div>
</template>
<script>
import OrdersScheduleMealExport from "@/components/Orders/Schedule/Meal/OrdersScheduleMealExport";

import ofa from "@/requests/stewards/ofa";
import * as actions from "@/store/action-types";
import OrderFunctionStatus from "@/components/Stewards/OrderFunctionStatus";
import { formatMealData } from "@/components/Orders/Schedule/format-schedule-data";

export default {
  name: "OrderFulfillmentSingleHistory",
  components: { OrderFunctionStatus, OrdersScheduleMealExport },
  data() {
    return {
      servicePartnerId: this.getDefaultSp("meal"),
      servicePartnerName: "",
      pageData: [],
      loading: false,
      exporting: false,
    };
  },
  computed: {
    date() {
      return this.$route.params.date;
    },
    title() {
      return this.formatDate(this.date, "do m, y");
    },
    tableTitle() {
      const result = this.pageData.length;
      return `${result} Order${result > 1 ? "s" : ""}`;
    },
  },

  watch: {
    servicePartnerId() {
      this.getOrderSingleHistory();
    },
  },

  created() {
    this.$store.dispatch(actions.GET_SERVICE_PARTNERS_LIST);
    this.getOrderSingleHistory();
  },
  methods: {
    setServicePartner(servicePartnerId) {
      this.servicePartnerId = servicePartnerId;
    },
    setCustomerAllergies(data) {
      let allergies = [];
      if (data) {
        if (data.seafood) allergies.push("seafood");
        if (data.shellfish) allergies.push("shellfish");
        if (data.nut) allergies.push("nut");
      }
      return allergies;
    },
    getOrderSingleHistory() {
      this.loading = true;
      ofa
        .order(this.servicePartnerId, this.date)
        .then((response) => {
          if (response.data.status) {
            this.pageData = formatMealData(response.data.data.data);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  text-align: left;

  .marker {
    display: block;
    font-size: 0.75rem;
    line-height: 16px;
    color: #4b6358;
    margin-left: 13px;
    margin-top: 5px;
    font-weight: 300;
  }
}

.meals {
  span {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
</style>
